const Palette = {
    secondly: '#cbff',
    danger: 'red',
    warning: '#ff9800',
    success: 'mediumseagreen',
    info: 'deepskyblue',
    lightGray: '#ececec',
    dark: '#3B5369',
    green: '#00a65a',
    blue: '#228AD0',
    dodgerblue: 'dodgerblue',
    theme: '#dfd2ab',
    // superLightTheme: '#f1ecdb',
    superLightTheme: '#f1ecdb',
    lightTheme: '#faf9f3',
    darkTheme: '#012E40',
    darkerTheme: '#d1bf87',
    inFocus: '#00a65a',
    red: 'red',
    lightRed: '#F69697',
    light: '#E1F2FC',
    gray: '#C7D2D9',
    darkerGray: '#ADBDC9',
    darker: '#173540',
    darkest: '#161F30',
    highlight: '#05F2C7',
    grayFont: '#61727C',
    contentBackground: '#f6f2e7',
    backgroundColor: '#f6f2e7',
    fontColor: '#63656e',
    yellow: '#ffc107',
    chartLabel: '#2a2f52',
    btnBackground: '#d1bf87',
    inputField: '#f6f2e7',
    gold: '#fed65b',
    silver: '#C0C0C0',
    bronze: '#e39f93'
}

export default Palette
