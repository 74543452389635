const initialState = {
  showEdit: false,
  showCreate: false,
  data: "",
  babyLabels: []
}
const BabyPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_BABY':
      return { ...state, ...action.payload }

    default: { return state }
  }
}

export default BabyPageEditReducer
