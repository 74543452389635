import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const BabyList = Loadable(lazy(() => import('./BabyList')))
const BabyBirthday = Loadable(lazy(() => import('./BabyBirthday')))

const babyRoutes = [
    {
        path: '/baby/list',
        element: <BabyList />,
    },
    {
        path: '/baby/birthday',
        element: <BabyBirthday />,
    },
]

export default babyRoutes
