import { combineReducers } from 'redux'
import ShopInfo from './ShopInfoReducer'
import BabyReducer from './BabyReducer'
import MummyReducer from './MummyReducer'
import Appointment from '../slice/Appointment'
import WebstoreReducer from './WebstoreReducer'
import ChatRoomSlice from '../slice/ChatRoomSlice'
import ScrumBoardReducer from './ScrumBoardReducer'
import NavigationReducer from './NavigationReducer'
import GlobalDataReducer from './GlobalDataReducer'
import BabyPageEditReducer from './BabyPageEditReduces'
import MummyPageEditReducer from './MummyPageEditReduces'
import StaffPageEditReducer from './StaffPageEditReducer'
import WabaTemplateSlice from '../slice/WabaTemplateSlice'
import QuotationAndDOSlice from '../slice/QuotationAndDOSlice'
import BillingAndInvoiceSlice from '../slice/BillingAndInvoiceSlice'
import TaskStatusReducer from './tasksDataReducer'

const RootReducer = combineReducers({
    shopInfo: ShopInfo,
    babyData: BabyReducer,
    mummyData: MummyReducer,
    chat_room: ChatRoomSlice,
    appointment: Appointment,
    globalData: GlobalDataReducer,
    scrumboard: ScrumBoardReducer,
    webstoreData: WebstoreReducer,
    navigations: NavigationReducer,
    waba_template: WabaTemplateSlice,
    quotation_do: QuotationAndDOSlice,
    babyPageEdit: BabyPageEditReducer,
    mummyPageEdit: MummyPageEditReducer,
    staffPageEdit: StaffPageEditReducer,
    billing_invoice: BillingAndInvoiceSlice,
    taskStatusReducer: TaskStatusReducer
})

export default RootReducer
