import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import './app.css'
import { Store } from './redux/Store'
import { AllPages } from './routes/routes'
import Theme from './components/Theme/theme'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import NotificationBar from 'app/components/NotificationBar'
import { SettingsProvider } from 'app/contexts/SettingsContext'


const App = () => {
    const all_pages = useRoutes(AllPages())
    const [updateAvailable, setUpdateAvailable] = useState(false);

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });

            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    registration.addEventListener('updatefound', () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.addEventListener('statechange', () => {
                                if (installingWorker.state === 'installed') {
                                    if (navigator.serviceWorker.controller) {
                                        setUpdateAvailable(true);
                                    }
                                }
                            });
                        }
                    });
                })
        }
    }, []);


    const handleCloseUpdateSnackbar = () => {
        setUpdateAvailable(false);
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
            // window.location.reload()
            window.location.replace('/dashboard_login');
        }

    };



    return (
        <Provider store={Store}>
            <SettingsProvider>
                <Theme>
                    <AuthProvider>
                        <Helmet>
                            <title>Caredemy Dashboard</title>
                        </Helmet>
                        {all_pages}
                        <NotificationBar handleUpdate={handleCloseUpdateSnackbar} open={updateAvailable} />
                        {/* <HomeScreenShortcut /> */}
                    </AuthProvider>
                </Theme>
            </SettingsProvider>
        </Provider>
    )
}

export default App
