
const initialState = {
    babyList: [],
    babyLabels: []
}
const BabyReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_BABY_TAGS': {
            return {
                ...state,
                babyLabels: [...action.payload]
            };
        }
        case 'ADD_NEW_TAG': {
            return {
                ...state,
                babyLabels: [
                    ...state.babyLabels,
                    action.payload
                ]
            };
        }
        case 'REMOVE_TAG': {
            return {
                ...state,
                babyLabels: state.babyLabels.filter(tag => tag.id !== action.payload)
            };
        }
        case 'REMOVE_TAG_FROM_BABYS': {
            return {
                ...state,
            };
        }
        default: {
            return state;
        }
    }
};

export default BabyReducer;
