import React, {useState, useEffect} from 'react'
import { Button, Snackbar, Typography } from '@mui/material';
import Palette from 'app/palette'
import { CustomSnackbarContent  } from 'app/utils/customStyle'
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken} from "firebase/messaging";
import {
    api_url,
    VAPIDKEY,
    firebaseConfig
} from 'app/utils/constant'

initializeApp(firebaseConfig);

function FmcNotification() {
    const [open, setOpen] = useState(false);
    const [deniedOpen, setDeniedOpen] = useState(false);
    
    const permission_void = () => {
        Notification.requestPermission()
        .then((permission) => {
            if (permission === 'granted') {
                const messaging = getMessaging();
                
                getToken(messaging, { 
                    vapidKey: VAPIDKEY 
                })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log("================== got currentToken")
                        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
                        const shopInfo = JSON.parse(localStorage.getItem('shopInfo'))
    
                        axios
                        .post(`${api_url}fcm_device/`, {
                            shop : shopInfo?.id,
                            registration_id : currentToken,
                            type : 'web'
                        },{
                            headers: {
                                Authorization: `token ${loginInfo.accessToken}`,
                            },
                        })
                        .then(() => {
                        })
                        .catch((error) => {
                            console.log("error");
                        })
    
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    };
                })
                .catch((err) => {
                    console.log('====++=====++============ ', err);
                    permission_void() // sometime getToken send error, so we request again for the token.
                });
            }
        });
        
        setOpen(false);
    };


    useEffect(() => {
        if ( 'Notification' in window ) {
            if( Notification.permission === 'default'){
                const stored_FCM_date = JSON.parse(localStorage.getItem('fcm_pop_up'));
                if([null, 'null', undefined].includes(stored_FCM_date?.accept)){
                    setOpen(true);
                    return
                };

                if(stored_FCM_date?.accept) return;

                const lastClicked = new Date(stored_FCM_date?.time);
                const current_date = new Date();
                const timeDifference = current_date - lastClicked ;
                const the_diff_day = 15;

                if (timeDifference >= (the_diff_day * 24 * 60 * 60 * 1000) ) {
                    setOpen(true);
                };

            }else if(Notification.permission === 'granted'){
                permission_void()
            }else if(Notification.permission === 'denied'){
                const notification_inst = localStorage.getItem('notification_inst');
                if([null, 'null', undefined].includes(notification_inst)){
                    localStorage.setItem('notification_inst', new Date().toISOString());
                    setDeniedOpen(true);
                    return
                };

                const lastClicked = new Date(notification_inst);
                const current_date = new Date();
                const timeDifference = current_date - lastClicked ;
                const the_diff_day = 15;

                if (timeDifference >= (the_diff_day * 24 * 60 * 60 * 1000) ) {
                    setDeniedOpen(true);
                };
            };
        };
    },[])

    const handleUpdateYes = () => {
        if ('serviceWorker' in navigator){
            navigator.serviceWorker.getRegistration()
            .then(registration => {
                if (registration) {
                    permission_void()
                } else {
                    registration.update();
                };
            })
            .catch(error => {
                console.error('Service Worker Error:', error);
            });
        };

        const options = {
            time : new Date(),
            accept : true
        };

        localStorage.setItem('fcm_pop_up', JSON.stringify(options));
    };


    const handleUpdateNo = () => {
        const options = {
            time : new Date(),
            accept : false
        };

        localStorage.setItem('fcm_pop_up', JSON.stringify(options));
        setOpen(false);
    };

    const handleUpdateClose = () => {
        localStorage.setItem('notification_inst', new Date().toISOString() );
        setDeniedOpen(false);
    };


    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
            >
                <CustomSnackbarContent
                    message="Turn on your notification"
                    action={
                        <>
                            <Button 
                                variant="contained" 
                                onClick={handleUpdateNo}
                                sx={{ marginLeft : 2 }}
                            >
                                <Typography sx={{ color: Palette.success, fontWeight: 'bold' }}> No </Typography>
                            </Button>

                            <Button 
                                variant="contained" 
                                onClick={handleUpdateYes}
                                sx={{ marginLeft : 2 }}
                            >
                                <Typography sx={{ color: Palette.success, fontWeight: 'bold' }}> Yes </Typography>
                            </Button>
                        </>
                    }
                />
            </Snackbar>


            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={deniedOpen}
            >
                <CustomSnackbarContent
                    message="Your are missing your notification! Go to site settings and select 'allow' for notification."
                    action={
                        <>
                            <Button 
                                variant="contained" 
                                onClick={handleUpdateClose}
                                sx={{ marginLeft : 2 }}
                            >
                                <Typography sx={{ color: Palette.success, fontWeight: 'bold' }}> Close </Typography>
                            </Button>
                        </>
                    }
                />
            </Snackbar>
        </>
    )
}

export default FmcNotification