import React, { createContext, useState } from 'react'
import { merge } from 'lodash'
import { MatxLayoutSettings } from 'app/components/Theme/Layout/settings'
import AccessCode from 'app/components/Dialog/Others/AccessCode'
import { useSelector } from 'react-redux'


const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => {},
})

export const SettingsProvider = ({ settings, children }) => {
    const userLockStatus = useSelector(state => state.globalData?.userLockStatus);
    const userLockStatusLocal = JSON.parse(localStorage.getItem('userLockStatus'))

    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <>
            <AccessCode
                openDialog={userLockStatus || userLockStatusLocal}
                updateDialogStatus={() => {}}
            />
            <SettingsContext.Provider
                value={{
                    settings: currentSettings,
                    updateSettings: handleUpdateSettings,
                }}
            >
                {children}
            </SettingsContext.Provider>
        </>
    )
}

export default SettingsContext
