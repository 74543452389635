import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Referral = Loadable(lazy(() => import('./Referral')))
const MummyList = Loadable(lazy(() => import('./MummyList')))
const Membership = Loadable(lazy(() => import('./Membership')))
const MummyBirthday = Loadable(lazy(() => import('./MummyBirthday')))

const mummyRoutes = [
    {
        path: '/mummy/list',
        element: <MummyList />,
    },
    {
        path: '/mummy/birthday',
        element: <MummyBirthday />,
    },
    {
        path: '/membership',
        element: <Membership />,
    },
    {
        path: '/referral',
        element: <Referral />,
    },
]

export default mummyRoutes
