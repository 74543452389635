import React, { createContext, useReducer, useEffect } from 'react'
import axios from 'axios.js'
import { api_url } from 'app/utils/constant'

export const StaffContext = createContext()
let shopInfo = JSON.parse(localStorage.getItem('shopInfo'))

const initialState = {
    business_type_list: [],
    staff_role_type: [],
    active_staff_role_data: null,
    adding_staff_data: {
        shop_id: shopInfo?.id,
        name: '',
        permissions: [],
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'STAFF_ROLE_TYPE_LIST':
            return {
                ...state,
                staff_role_type: action.payload,
            }

        case 'ACTIVE_STAFF_DATA':
            return {
                ...state,
                active_staff_role_data: {
                    ...state.active_staff_role_data,
                    ...action.payload,
                },
            }

        case 'ADDING_STAFF_DATA':
            return {
                ...state,
                adding_staff_data: {
                    ...state.adding_staff_data,
                    ...action.payload,
                },
            }

        case 'BUSINESS_TYPE':
            return {
                ...state,
                business_type_list: action.payload,
            }

        default:
            return state
    }
}

const StaffContextProvider = ({ children }) => {
    const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
    const [staffState, staffContextDispatch] = useReducer(reducer, initialState)

    const handleRoleLoad = (async () => {
        await axios
            .get(`${api_url}role/`, {
                headers: {
                    Authorization: `token ${loginInfo?.accessToken}`,
                },
                params: {
                    shop_id: shopInfo?.id,
                },
            })
            .then((data) => {
                staffContextDispatch({
                    type: 'STAFF_ROLE_TYPE_LIST',
                    payload: data.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    })

    const handleBusinessTypeLoad = (async () => {
        await axios
            .get(`${api_url}business_type/${shopInfo?.business_type?.id}/`, {
                headers: {
                    Authorization: `token ${loginInfo?.accessToken}`,
                },
            })
            .then((data) => {
                staffContextDispatch({
                    type: 'BUSINESS_TYPE',
                    payload: data.data,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    })

    useEffect(() => {
        handleRoleLoad()
        handleBusinessTypeLoad()
    }, [])


    return (
        <StaffContext.Provider value={[staffState, staffContextDispatch]}>
            {children}
        </StaffContext.Provider>
    )
}

export default StaffContextProvider
