
const initialState = {
  tasks: [],
  downloaded_tasks: [],
}
const TaskStatusReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'ADD_TASK': {
      return {
        ...state,
        tasks: [...state.tasks, ...action.payload]
      }
    }
    case 'REMOVE_TASK': {
      if (state.tasks.length == 1) {
        return {
          ...state,
          tasks: []
        }
      }
      return {
        ...state,
        tasks: state.tasks.filter(t => t != action.payload)
      }
    }
    case 'DOWNLOADED': {
      return {
        ...state,
        downloaded_tasks: [...state.downloaded_tasks, ...action.payload]
      }
    }
    default: {
      return state
    }
  }
}

export default TaskStatusReducer
