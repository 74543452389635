import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const DeliveryOrder = Loadable(lazy(() => import('./DeliveryOrder')))
const History = Loadable(lazy(() => import('./History')))
const DeliveryOrderPDF = Loadable(lazy(() => import('./DeliveryOrderPDF')))

const deliveryOrderRoutes = [
    {
        path: '/delivery_order',
        element: <DeliveryOrder />,
    },
    {
        path: '/delivery_order/history',
        element: <History />,
    },
    {
        path: '/delivery_order_doc',
        element: <DeliveryOrderPDF />,
    },
]

export default deliveryOrderRoutes
