
const initialState = {
    mummyList: [],
    mummyLabels: []
}
const MummyReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_MUMMY_TAGS': {
            return {
                ...state,
                mummyLabels: [...action.payload]
            };
        }
        case 'ADD_NEW_TAG': {
            return {
                ...state,
                mummyLabels: [
                    ...state.mummyLabels,
                    action.payload
                ]
            };
        }
        case 'REMOVE_TAG': {
            return {
                ...state,
                mummyLabels: state.mummyLabels.filter(tag => tag.id !== action.payload)
            };
        }
        case 'REMOVE_TAG_FROM_MUMMYS': {
            return {
                ...state,
            };
        }
        default: {
            return state;
        }
    }
};

export default MummyReducer;
