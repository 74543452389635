const initialState = {
  showEdit: false,
  showCreate: false,
  data: "",
  mummyLabels: []
}
const MummyPageEditReducer = function (state = initialState, action) {
  switch (action.type) {
    case 'SET_VALUE_MUMMY':
      return { ...state, ...action.payload }

    default: { return state }
  }
}

export default MummyPageEditReducer
